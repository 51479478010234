import React, {useEffect, useLayoutEffect, useState} from 'react';
import {Layout, SEO} from '../components';
import sizeMe from 'react-sizeme';
import '../assets/styles/pages/services.scss';
import {Helmet} from "react-helmet";

const Services = ({pageContext: {service_data}, ...props}) => {
    const [pageData, setPageData] = useState(null);

    // const footer = document.getElementById('footer') !== null && document.getElementById('footer');

    const service_meta_data = service_data?.data;

    useEffect(() => {
        fetch('https://cms.musicroom.info/api/abouts')
            .then((response) => response.json())
            .then(res => {
                console.log(res)
                return res;
            })
            .then((res) => res.data ? setPageData(res.data) : setPageData(res));
    }, [])

    const offset = (el) => {
        const rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return {top: rect?.top + scrollTop, left: rect?.left + scrollLeft}
    }

    const scrollToElement = (queryParam) => {
        const elem = document.getElementById(queryParam);
        window.scrollTo({
            top: offset(elem).top,
            behavior: 'smooth'
        })
    }

    useLayoutEffect(() => {
        window.location.search && setTimeout(() => {
            const urlParams = new URLSearchParams(window.location.search);
            const myParam = urlParams.get('section');
            myParam ? scrollToElement(myParam) : window.scrollTo({top: 0, behavior: 'smooth'});
        }, 700)
    }, [])

    const section_1_left = pageData?.about?.section_1?.split('<hr />')[0];
    const section_1_right = pageData?.about?.section_1?.split('<hr />')[1];

    const section_2_left = pageData?.about?.section_2?.split('<hr />')[0];
    const section_2_right = pageData?.about?.section_2?.split('<hr />')[1];



    return (
        <>
            <Layout>
                <SEO 
                    title={service_meta_data?.title || "MusicRoom I About us and our services"}
                    showDefaultTitle={false}
                    description={service_meta_data?.description || "MusicRoom provides artists for your concerts"}
                    image={service_meta_data?.image}
                    keywords={service_meta_data?.keywords}
                />

                <div className='services'>
                    <div className='service-section top-border' id='musicroom'>
                        <div className='service-section-1 topBorder'>
                            <h1 className='service-title'>
                                ABOUT <br/> <span className='strokeText'>MUSIC ROOM</span>
                            </h1>
                            <div className='twoColumns'>
                                <p className='paragraph left'
                                   dangerouslySetInnerHTML={{__html: section_1_left}}/>
                                <p className='paragraph right'
                                   dangerouslySetInnerHTML={{__html: section_1_right}}/>
                            </div>
                        </div>
                    </div>
                    <div className='service-section'>
                        <div className='service-section-1'>
                            <div className='twoColumns'>
                                <p className='paragraph left'
                                   dangerouslySetInnerHTML={{__html: section_2_left}}/>
                                <p className='paragraph right'
                                   dangerouslySetInnerHTML={{__html: section_2_right}}/>
                            </div>
                        </div>
                    </div>

                    <div className='service-section whiteBg' id='services'>
                        <div className='service-section-2'>
                            <h1 className='service-title'>
                                SERVICES <br/> <span className='strokeText'>IN THE ROOM</span>
                            </h1>
                            <div className='service-section-2-content'>
                                <p className='paragraph'
                                   dangerouslySetInnerHTML={{__html: pageData?.service?.part_1?.title}}/>
                                <div className='blocks'>
                                    {pageData?.service?.part_1?.items?.map((item, i) => {
                                        return (
                                            <div key={i} className='blocks-item'>
                                                <div className='blocks-item-wrapper'>
                                                    <div className='number'>0{i < 10 ? i + 1 : i}</div>
                                                    <span className='txt' dangerouslySetInnerHTML={{__html: item}}/>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='service-section whiteBg'>
                        <div className='service-section-2'>
                            <div className='service-section-2-content noMarginTop'>
                                <p className='paragraph orange stickyElement'
                                   dangerouslySetInnerHTML={{__html: pageData?.service?.part_2?.title}}/>
                                <div className='blocks column'>
                                    {pageData?.service?.part_2?.items?.map((item, i) => {
                                        return (
                                            <div key={i} className='blocks-item'>
                                                <div className='blocks-item-wrapper'>
                                                    <div className='number'>0{i < 10 ? i + 1 : i}</div>
                                                    <span className='txt' dangerouslySetInnerHTML={{__html: item}}/>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default Services;
